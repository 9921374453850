<template>
  <div class="Detail rtl">
    <!-- 加载中 -->
    <div v-if="loading" class="loading-wrap">
      <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
    </div>

    <template v-if="!loading">
      <van-nav-bar title="ەرەكشە قيمىل" @click-left="$router.back()" @click-right="$router.push('/')" fixed>
        <div slot="left" style="color: #fff; font-size: 16px">
          قايتۋ
          <van-icon name="arrow-left" size="16px" />
        </div>
        <div slot="right" style="color: #fff; font-size: 16px">
          <van-icon name="wap-home-o" size="18px" />
          باس بەت
        </div>
      </van-nav-bar>
      <div class="top_img">
        <van-image class="avatar" fit="cover" :src="activiryDetail.cover" />
        <h4 class="teacherName">{{ activiryDetail.title }}</h4>
        <div class="teacherDesc" v-html="activiryDetail.desc"></div>
      </div>
      <van-notice-bar
        v-if="isPayActivity"
        :scrollable="false"
        wrapable
        text="سالەمەتسىز، ءسىز قيمىلعا قاتىسىپ بۇل ساباقتاردى كورۋ ۇقىعىنا يە بولدىڭىز. تومەندەگى ساباقتاردى باسىپ ۇيرەنىڭىز."
        style="margin-bottom: 10px"
      />
      <CourseItem :hots="courseList" />
      <SubmitBar v-if="!isPayActivity" label="قيمىل باعاسى" :price="totalPrice" button-text="ساتىپ الۋ" :tip="`قۇنى ${courseTotalPrice} يۋاندىق ساباقتى قيمىل باعادا ${activiryDetail.price} يۋانعا الا الاسىز`" @submit="onSubmit" safe-area-inset-bottom />
      <div style="height: 100px" v-if="!isPayActivity"></div>
    </template>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { wechatRedirect } from '@/api/user'
import { activityDetail, pay, payResult } from '@/api/Activity'
import CourseItem from '@/components/Course-item'
import { SubmitBar } from 'vant'
import { initShareInfo } from '@/utils/util'

export default {
  name: 'Detail',
  props: {},
  data () {
    return {
      loading: true,
      activiryDetail: {},
      courseList: [],
      totalPrice: 0,
      courseTotalPrice: 0,
      course_ids: [],
      isPayActivity: false
    }
  },
  components: {
    CourseItem,
    SubmitBar
  },
  mounted () {
    setTimeout(() => {
      this.queryData()
    }, 600)
  },
  methods: {
    async queryData () {
      try {
        const { data } = await activityDetail({
          id: this.$route.params.id,
          activityCode: this.$route.params.code
        })
        this.activiryDetail = data.activiryDetail
        this.isPayActivity = data.isPayActivity
        if (data.courseList.rows.length) {
          this.courseList = data.courseList.rows
          const price = data.courseList.rows.reduce((pre, cur) => {
            return pre + cur.price * 100
          }, 0)
          this.courseTotalPrice = (price / 100).toFixed(2)
          this.count = data.courseList.count
          this.totalPrice = data.activiryDetail.price * 100
          this.course_ids = data.courseList.rows.map((item) => item.id)
        }

        wx.ready(() => {
          initShareInfo({
            title: `${data.activiryDetail.title} - ارنا ساباقحاناسى`, // 分享标题
            imgUrl: data.activiryDetail.cover // 分享图标
          })
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async onSubmit () {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      // 支付请求参数
      const params = {
        openId,
        activity_name: this.activiryDetail.title,
        activity_code: this.activiryDetail.activity_code,
        activity_price: this.activiryDetail.price,
        course_ids: this.course_ids.join(',')
      }
      const { code, data } = await pay(params)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.args.timeStamp,
          nonceStr: data.args.nonceStr,
          package: data.args.package,
          signType: data.args.signType,
          paySign: data.args.paySign,
          success (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              payResult({
                activity_name: _this.activiryDetail.title,
                activity_code: _this.activiryDetail.activity_code,
                activity_price: _this.activiryDetail.price,
                course_ids: _this.course_ids.join(','),
                order_number: data.orderInfo.order_number
              })

              setTimeout(() => {
                _this.queryData()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: function (res) {
            alert(res.message || 'تەلەفوننان اقشا تولەڭىز')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .Detail {
    padding-top: 92px;
    background-color: #fff;
    min-height: 100vh;
    .top_img {
      position: relative;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding-top: 20px;
      border-radius: 6px;

      .avatar {
        width: 90%;
        height: 300px;
        border: 6px solid #fff;
      }

      .teacherName {
        font-size: 34px;
        color: #333;
        text-align: center;
        margin: 20px 0;
      }

      .teacherDesc {
        font-size: 30px;
        color: #999;
        text-align: justify;
        margin: 30px;
        margin-top: 10px;
        line-height: 1.3;
      }
    }

    /deep/ .van-submit-bar__text {
      padding-right: 0;
      > span.van-submit-bar__price {
        direction: ltr;
        margin-right: 20px;
      }
    }
  }
</style>
